<template>
  <v-app>
    <v-card>
      <div class="card-header">
        <div class="card-title pt-3 px-3 d-flex justify-content-between">
          <v-row>
            <v-col cols="12" md="3" class="pt-6">
              <h2>{{ history_id ? "Update" : "New" }} {{type}} transaction</h2>
              <p v-if="history.paid_for">
                for <span class="text-lg-body-1">{{history.paid_for}}</span>
                <span v-if="history.application_title" class="text-lg">-{{history.application_title}}</span>
              </p>
            </v-col>
            <v-col cols="12" md="3" class="pt-6">
              <v-select
                  outlined
                  dense
                  v-model="search.type"
                  :items="user_types"
                  @change="onChangeType"
                  label="New transaction"
                  item-value="value"
                  item-text="name"
              ></v-select>
            </v-col>

            <v-col cols="12" md="3" class="pt-6" v-if="search.type != 'other'">
              <span class="text-lg" v-if="history.paid_for">{{history.paid_for}}</span>
              <v-autocomplete
                  outlined
                  dense
                  v-model="user"
                  :items="items"
                  :search-input.sync="search_sync"
                  item-text="display_text"
                  item-value="id"
                  label="Name, email, business name, phone"
                  placeholder="Start typing to search"
                  prepend-inner-icon="mdi-database-search"
                  @change="prefillUserData()"
                  small-chips
                  deletable-chips
                  return-object
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" md="3" class="pt-6" v-if="search.type != 'other'">
              <v-select
                  outlined
                  dense
                  v-model="application"
                  :items="applicationLists"
                  item-text="title"
                  item-value="id"
                  @change="history.application_id = application"
                  label="Application reference no"
                  prepend-inner-icon="mdi-database-search"
              ></v-select>
            </v-col>
          </v-row>
        </div>
      </div>

      <div class="card-body">
        <v-row>
          <v-col cols="12" md="6">
            <div>
              <v-select
                  :items="['parent', 'self', 'guardian']"
                  v-model="history.paid_by"
                  outlined
                  dense
                  label="Paid by"
              ></v-select>
              <span class="text-danger" v-if="$v.history.paid_by.$error"
              >This information is required</span
              >
            </div>

            <div class="mt-4" v-if="history.paid_by != 'self'">
              <v-text-field
                  v-model="history.payer_relation"
                  outlined
                  dense
                  label="Payer relation"
              ></v-text-field>
              <span class="text-danger" v-if="$v.history.payer_relation.$error"
              >This information is required</span
              >
            </div>

            <div class="mt-4">
              <v-text-field
                  outlined
                  dense
                  :label="type == 'income' ? 'Payer name' : 'Payee name'"
                  v-model="history.payer_name"
              >
              </v-text-field>
              <span class="text-danger" v-if="$v.history.payer_name.$error"
              >This information is required</span
              >
            </div>

            <div class="mt-4">
              <v-text-field
                  outlined
                  dense
                  :label="type == 'income' ? 'Payer email' : 'Payee email'"
                  v-model="history.payer_email"
              >
              </v-text-field>
              <span class="text-danger" v-if="$v.history.payer_email.$error"
              >This information is required</span
              >
            </div>

            <div class="mt-4">
              <v-text-field
                  outlined
                  dense
                  :label="type == 'income' ? 'Payer phone' : 'Payee phone'"
                  v-model="history.payer_mobile"
              >
              </v-text-field>
              <span class="text-danger" v-if="$v.history.payer_mobile.$error"
              >This information is required</span
              >
            </div>

            <div class="mt-4">
              <v-text-field
                  outlined
                  dense
                  :label="type == 'income' ? 'Payer address' : 'Payee address'"
                  v-model="history.payer_address"
              >
              </v-text-field>
              <span class="text-danger" v-if="$v.history.payer_address.$error"
              >This information is required</span
              >
            </div>
          </v-col>

          <v-col cols="12" md="6" class="text-right">
            <div>
              <v-text-field
                  outlined
                  dense
                  type="date"
                  label="Payment date"
                  v-model="history.payment_date"
                  style="max-width: 200px; float: right"
              >
              </v-text-field>
              <span class="text-danger" v-if="$v.history.payment_date.$error"
              >This information is required</span
              >
              <br style="width: 100%; clear: both" />
            </div>

            <div class="mt-4">
              <v-select
                  label="Tax option"
                  :items="tax_types"
                  outlined
                  dense
                  v-model="tax_type"
                  item-value="value"
                  style="max-width: 160px; float: right"
                  @change="calculateTotal"
                  item-text="title"
              ></v-select>
              <br style="width: 100%; clear: both" />
            </div>

            <div class="mt-4">
              <v-select
                  outlined
                  dense
                  :items="payment_types"
                  v-model="history.payment_status"
                  @change="changePaymentStatus"
                  item-text="title"
                  item-value="value"
                  label="Type"
                  style="max-width: 160px; float: right"
              >
              </v-select>
              <span class="text-danger" v-if="$v.history.payment_status.$error"
              >This information is required</span
              >
            </div>
          </v-col>

          <v-col cols="12">
            <table class="table">
              <thead>
              <tr class="px-3">
                <th class="px-3 wrap-column">Transaction heading</th>
                <th class="px-3 wrap-column">Payment type</th>
                <th class="px-3 wrap-column">Title</th>
                <th class="px-3 wrap-column">Quantity</th>
                <th class="px-3 wrap-column">Amount</th>
                <th class="px-1 text-center">Action</th>
              </tr>
              </thead>
              <tr v-for="(item, i) in history_items" :key="i" style="border: hidden">
                <td class="px-3 wrap-column">
                  {{ item.transaction_head_title }}
                </td>
                <td class="px-3 wrap-column">
                  {{ item.payment_type_title }}
                </td>
                <td class="px-3 wrap-column">
                  {{ item.title ? item.title : "-" }}
                </td>
                <td class="px-3 wrap-column">
                  {{ item.quantity }}
                </td>

                <td class="px-3 wrap-column">
                  {{ item.item_price }}
                </td>
                <td class="pr-0 text-center">
                  <v-icon @click="removeItem(i, item.id)" color="red" small>fas fa-trash</v-icon>
                </td>
              </tr>
              <tr v-if="history_items.length < 1">
                <td class="text-center" colspan="8">Data not available</td>
              </tr>

            </table>
            <hr />
          </v-col>

          <v-col cols="12">
            <table class="table" style="background-color: rgb(242 254 247)">
              <thead>
              <tr style="border: hidden">
                <td colspan="6" class="pt-5"><h5>Add invoice item</h5></td>
              </tr>
              </thead>

              <tbody>
              <tr style="border: hidden; background-color: rgb(242 254 247) !important">
                <td class="px-3 pt-6 py-5" style="width: 20%">
                  <v-select
                      v-model="history_item.financial_transaction_head"
                      placeholder="Transaction heading"
                      :items="financial_heads"
                      item-text="title"
                      outlined
                      dense
                      @change="getPaymentType($event)"
                      return-object
                  ></v-select>
                  <span
                      class="text-danger"
                      v-if="
                        !history_item.financial_transaction_head_id &&
                        $v.history_item.financial_transaction_head_id.$error
                      "
                  >This information is required</span
                  >
                </td>

                <td class="px-3 pt-6 py-5" style="width: 20%">
                  <v-select
                      v-model="history_item.financial_payment_type_id"
                      placeholder="Payment type"
                      :items="financial_payment_types"
                      item-text="title"
                      item-value="id"
                      outlined
                      dense
                      @change="getPaymentTypeById(history_item.financial_payment_type_id)"
                  ></v-select>
                  <span
                      class="text-danger"
                      v-if="
                        !history_item.financial_payment_type_id &&
                        $v.history_item.financial_payment_type_id.$error
                      "
                  >This information is required</span
                  >
                </td>

                <td class="px-3 pt-6 py-5" style="width: 20%" v-if="history_item.financial_payment_type_id == 'other'">
                  <v-text-field
                      outlined
                      dense
                      v-model="history_item.title"
                      placeholder="Title"
                  ></v-text-field>
                  <span
                      class="text-danger"
                      v-if="
                        !history_item.financial_payment_type_id == 'other' &&
                        $v.history_item.title.$error
                      "
                  >This information is required</span
                  >
                </td>

                <td class="px-3 pt-6 py-5" style="width: 10%">
                  <v-text-field
                      type="number"
                      min="0"
                      placeholder="Qty"
                      outlined
                      dense
                      v-model="history_item.quantity"
                  ></v-text-field>
                  <span
                      class="text-danger"
                      v-if="!history_item.quantity && $v.history_item.quantity.$error"
                  >This information is required</span
                  >
                </td>

                <td class="px-3 pt-6 py-5" style="width: 20%">
                  <v-text-field
                      type="number"
                      min="0"
                      outlined
                      dense
                      placeholder="Amount"
                      v-model="history_item.item_price"
                  ></v-text-field>
                  <span
                      class="text-danger"
                      v-if="!history_item.item_price && $v.history_item.item_price.$error"
                  >This information is required</span
                  >
                </td>
                <td class="pt-6 py-5 pr-6">
                  <v-btn
                      class="btn-primary btn-xs float-right btn text-white"
                      @click="addNewTransactionHeading(history_item)"
                  >
                    Add
                  </v-btn>
                </td>
              </tr>
              </tbody>
            </table>
          </v-col>

          <v-col cols="12" v-if="type == 'income'">
            <table class="table" style="background-color: rgb(240 240 240)">
              <thead>
              <tr class="pt-5" style="border: hidden">
                <td colspan="2"><h5>Add discount</h5></td>
              </tr>
              </thead>
              <tbody>
              <tr
                  style="border: hidden; background-color: rgb(240 240 240) !important"
              >
                <v-row class="mb-3 mt-4 mx-3">
                  <v-col cols="12" md="6">
                    <div @input="validateDiscountAmount" v-if="type == 'income'">
                      <v-select
                          outlined
                          dense
                          :items="['fixed', 'percentage']"
                          label="Discount type"
                          v-model="history.discount_type"
                      ></v-select>
                    </div>
                  </v-col>

                  <v-col cols="12" md="6">
                    <div v-if="history.discount_type">
                      <v-text-field
                          @blur="validateDiscountAmount"
                          v-if="history.discount_type == 'fixed'"
                          outlined
                          dense
                          label="Discount amount"
                          v-model="history.discount_amount"
                      >
                      </v-text-field>
                      <v-text-field
                          @input="validateDiscountAmount"
                          v-if="history.discount_type == 'percentage'"
                          outlined
                          dense
                          label="Discount percentage"
                          v-model="history.discount_percentage"
                      >
                      </v-text-field>
                    </div>
                  </v-col>
                </v-row>
              </tr>
              </tbody>
            </table>
          </v-col>

          <v-col cols="12" md="6">
          </v-col>

          <v-col cols="12" md="6" class="text-right">
            <table class="table">
              <tr>
                <th class="text-right">Sub total</th>
                <td class="text-right">Rs.{{ history.sub_total || "00.00" }}</td>
              </tr>
              <tr v-if="type == 'income'">
                <th class="text-right">Discount</th>
                <td class="text-right">Rs.{{ history.discount_amount || "00.00" }}</td>
              </tr>

              <tr v-if="history.discount_amount">
                <th class="text-right">After discount</th>
                <td class="text-right">
                  Rs.{{
                    parseFloat(history.sub_total) - parseFloat(history.discount_amount) ||
                    "00.00"
                  }}
                </td>
              </tr>
              <tr>
                <th class="text-right">Tax</th>
                <td class="text-right">Rs.{{ history.gst_amount || "00.00" }}</td>
              </tr>


              <tr>
                <th class="text-right">Total</th>
                <td class="text-right">
                  <span style="font-size: 18px; font-weight: bold">
                    Rs.{{ history.total_amount || "00.00" }}</span
                  >
                </td>
              </tr>
            </table>
          </v-col>

          <v-col cols="12" md="8">
            <div
                class="rounded"
                style="background-color: rgb(240 240 240) !important"
            >
              <v-textarea
                  rows="9"
                  outlined
                  dense
                  label="Remark"
                  v-model="history.remarks"
              >
              </v-textarea>
            </div>
          </v-col>

          <v-col cols="12" md="4">
            <v-row v-if="type == 'income'">
              <v-col cols="12" class="text-right">
                <v-text-field
                    @input="calculateReturnCash"
                    outlined
                    dense
                    type="number"
                    label="Cash received"
                    v-model="history.received_cash"
                >
                </v-text-field>
                <span class="text-danger" v-if="$v.history.received_cash.$error"
                >This information is required</span
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" v-if="type == 'income'">
                <v-card
                    v-if="history.payment_method == 'offline' && paymentDetail"
                    class="mx-auto"
                    max-width="500"
                >
                  <v-card-text>
                    <p class="text-h4 text--primary">
                      {{ paymentDetail.title }}
                    </p>
                    <v-img class="m-14 text-center" v-if="paymentDetail.bank_qr_code_path" max-width="350" :src="paymentDetail.bank_qr_code_path['real']"></v-img>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" class="text-right">
                <div style="background-color: #f8ebd5" class="px-4 py-5 rounded">
                  <v-row>
                    <v-col cols="12">
                      <v-switch
                          v-if="type == 'income'"
                          v-model="use_as_advance"
                          label="Paying in advance"
                          @change="advanceCashReceived"
                      ></v-switch>

                      <div class="mt-4" v-if="type == 'income'">
                        <v-text-field
                            outlined
                            v-if="use_as_advance"
                            dense
                            readonly
                            type="number"
                            label="Total advance amount "
                            v-model="history.advance_received"
                        >
                        </v-text-field>
                      </div>

                      <div class="mt-4">
                        <v-select
                            outlined
                            dense
                            @change="changePaymentType"
                            :items="payment_method"
                            v-model="history.payment_method"
                            item-text="title"
                            item-value="value"
                            label="Payment method"
                        >
                        </v-select>
                        <span class="text-danger" v-if="$v.history.payment_method.$error"
                        >This information is required</span
                        >
                      </div>
                    </v-col>
                  </v-row>
                  <v-row v-if="history.change_return">
                    <v-col cols="5" class="pt-7">
                        <span style="font-size: 1.5rem !important; color: red; margin-top: 10px;" >Change</span
                        ></v-col
                    >
                    <v-col cols="7" class="text-right" style="background-color: #f8ebd5">
                      <h1 style="color: red; font-size: 4rem !important">
                        {{ history.change_return }}
                      </h1>
                    </v-col>
                  </v-row>
                </div>
              </v-col>

            </v-row>
          </v-col>
        </v-row>

        <v-row>

        </v-row>

        <div class="row">
          <div class="col-12">
            <v-btn
                :disabled="disable_save"
                class="btn btn-primary text-white float-right"
                @click="validateForm()"
            >
              {{ history_id ? "Update" : "Save" }}
            </v-btn>
          </div>
        </div>

      </div>
    </v-card>
  </v-app>
</template>

<script>
import FinanceMixin from "@/mixins/FinanceMixin";
import {required, email, requiredIf} from "vuelidate/lib/validators";
import UserService from "@/services/user/UserService";
import FinancialHistoryService from "@/services/finance/history/FinancialHistoryService";
import FinancialHistoryItemService from "@/services/finance/history/item/FinancialHistoryItemService";
import PaymentGatewaySettingService from "@/services/cms/site-setting/payment-gateway/PaymentGatewaySettingService";
import ApplicationService from "@/services/application/ApplicationService";

const userService = new UserService();
const application = new ApplicationService();
const historyService = new FinancialHistoryService();
const itemService = new FinancialHistoryItemService();
const paymentGatewaySetting = new PaymentGatewaySettingService();

export default {
  name: "CreateOrUpdate",
  mixins: [FinanceMixin],
  validations() {
    return {
      history: this.history_rules,
      history_item: this.history_item_rule,
    };
  },
  data() {
    return {
      user: {},
      application: {},
      user_lists: [],
      applicationLists: [],
      history_items: [],
      isLoading: false,
      use_as_advance: false,
      disable_save: false,
      descriptionLimit: 60,
      tax_type: "inclusive",
      history: {
        application_id: null,
        payment_status: null,
        payment_method: null,
        payment_date: null,
        payer_name: null,
        payer_email: null,
        payer_mobile: null,
        payer_address: null,
        payer_relation: null,
        paid_by: "self",
        is_full_paid: 1,
        discount_amount: null,
        type: null,
        discount_type: null,
        discount_percentage: null,
        change_return: null,
        received_cash: null,
      },
      history_item: {
        financial_transaction_head_id: null,
        financial_payment_type_id: null,
        title: null,
        quantity: null,
        item_price: null,
      },
      search: {
        type: "student",
        name: "",
      },
      filter: {
        application_created_by: "",
        applicant_id: "",
        is_draft: 0,
        is_ready_to_submit: 1
      },
      search_sync: null,
      tax_types: [
        { title: "Tax Inclusive", value: "inclusive" },
        { title: "Tax Exclusive", value: "exclusive" },
        { title: "No Tax", value: "no" },
      ],
      user_types: [
        { name: "Student", value: "student" },
        { name: "Agent", value: "agent" },
        { name: "Onshore", value: "onshore" },
        { name: "Other", value: "other" },
      ],
      payment_types: [
        {
          title: "Quote",
          value: "pending",
        },
        {
          title: "Invoice",
          value: "paid",
        },
      ],
      paymentDetail: {},
      payment_method: [
        {
          title: "Bank",
          value: "offline",
        },
        {
          title: "Cash",
          value: "cash",
        },
        {
          title: "Eftpos",
          value: "eftpos",
        }
      ],
    };
  },
  computed: {
    type() {
      return this.$route.params.type;
    },
    fields() {
      if (!this.model) return [];
      return Object.keys(this.model).map((key) => {
        return {
          key,
          value: this.model[key] || "n/a",
        };
      });
    },
    items() {
      return this.user_lists.map((user) => {
        let display_text;
        if(user.business_name) {
          display_text = user.full_name+' - '+ user.business_name;
        } else {
          display_text = user.full_name;
        }
        return Object.assign({}, user, { display_text });
      });
    },

    history_id() {
      return this.$route.params.id;
    },
    history_rules() {
      let rule = {
        payment_status: { required },
        payment_method: { required },
        payment_date: { required },
        payer_name: { required },
        payer_email: { required, email },
        payer_mobile: { required },
        payer_address: { required },
        payer_relation: { required: requiredIf(function () {
            return this.history.paid_by != 'self'
          })
        },
        paid_by: { required },
      };
      if (this.type == "income") {
        rule.received_cash = { required };
      }
      return rule;
    },
    history_item_rule() {
      return {
        financial_transaction_head_id: { required },
        financial_payment_type_id: { required },
        quantity: { required },
        item_price: { required },
      };
    },
  },
  watch: {
    search_sync(val) {
      if(this.isLoading) return;
      this.isLoading = true
      this.search.name = val;
      userService
          .getAllByUserType(this.search)
          .then((response) => {
            this.user_lists = response.data.users;
          })
          .catch(() => { })
          .finally(() => (this.isLoading = false));
    },
  },
  mounted() {
    this.history.type = this.type;
    this.getAllActiveTransactionHead(this.type);
    if(this.history_id) {
      this.getHistory()
    }
  },
  methods: {
    onChangeType() {
      this.user = {};
      this.history.applicant_id = null;
      this.history.application_id = null;
      this.history.payer_name = null;
      this.history.payer_mobile = null;
      this.history.payer_email = null;
      this.history.payer_user_id = null;
      this.applicationLists = [];
      this.user_lists = [];
    },

    changePaymentStatus() {
      if(this.history.payment_status == 'pending') {
        this.history.is_quote = 1;
      } else {
        this.history.is_quote = 0;
      }
    },

    getAllApplication() {
      application
          .filterApplications(this.filter)
          .then(response => {
            this.applicationLists = response.data.data;
          })
          .catch(() => {
          });
    },

    prefillUserData() {
      this.history.payer_name = this.user.full_name;
      this.history.payer_mobile = this.user.mobile;
      this.history.payer_email = this.user.email;
      this.history.payer_user_id = this.user.id;
      if(this.search.type === 'agent') {
        this.filter.application_created_by = this.user.id;
      } else {
        this.filter.applicant_id = this.user.id;
      }
      this.getAllApplication();
    },

    addNewTransactionHeading(item) {
      this.$v.history_item.$touch();
      if (this.$v.history_item.$error) {
        setTimeout(() => {
          this.$v.history_item.$reset();
        }, 3000);
      } else {
        let exist = false;
        if (this.history_items.length > 0) {
          this.history_items.forEach((ele) => {
            if (
                item.financial_payment_type_id == ele.financial_payment_type_id &&
                item.financial_transaction_head_id == ele.financial_transaction_head_id
            ) {
              this.$snotify.error("Item already exist in list");
              exist = true;
            }
          });
        }
        if (!exist) {
          this.history_items.push(item);
        }
        this.resetHistoryItem();
      }
    },

    resetHistoryItem() {
      this.history_item = {
        financial_transaction_head_id: null,
        financial_payment_type_id: null,
        title: null,
        quantity: null,
        item_price: null,
      };
      this.calculateTotal();
      this.$v.history_item.$reset();
    },

    removeItem(index, id = null) {
      this.$confirm({
        message: `Are you sure you want to delete this item?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            if (id) {
              itemService.delete(id).then(() => {
                this.$snotify.success("Item Information deleted");
              });
            }
            this.history_items.splice(index, 1);
            this.calculateTotal();
          }
        },
      });
    },

    getHistory() {
      historyService.show(this.history_id).then((response) => {
        this.history = response.data.history;
        if(this.history.is_agent) {
          this.search.type = 'agent';
        }
        if(this.history.is_agent) {
          this.search.type = 'student';
        }
        if(this.history.is_agent) {
          this.search.type = 'other';
        }
        this.getHistoryItems();
      });
    },

    getHistoryItems() {
      itemService.getAllByHistory(this.history.id).then((response) => {
        this.history_items = response.data.data;
        this.calculateTotal();
      });
    },

    validateDiscountAmount() {
      this.removeCashReturn();
      if (
          this.history.discount_type == "fixed" &&
          parseFloat(this.history.discount_amount) < parseFloat(this.history.sub_total)
      ) {
        this.calculateTotal();
      } else if (this.history.discount_type == "percentage") {
        this.calculateTotal();
      } else {
        this.history.discount_amount = 0;
        this.$snotify.error("Discount amount cannot be more than actual amount");
        this.calculateTotal();
      }
    },

    calculateTotal() {
      let subtotal = 0;
      if (this.history_items.length < 0) return;
      let taxPercentage = this.mySiteSetting.tax_percentage || 0;
      this.history_items.forEach((ele) => {
        subtotal += parseFloat(ele.item_price);
      });
      this.history.sub_total = subtotal;
      if (this.history.discount_amount && this.history.discount_type == "fixed") {
        subtotal = parseFloat(subtotal) - parseFloat(this.history.discount_amount);
      }
      if (
          this.history.discount_percentage &&
          this.history.discount_type == "percentage"
      ) {
        this.history.discount_amount =
            (parseFloat(subtotal) * parseFloat(this.history.discount_percentage)) / 100;
        subtotal = parseFloat(subtotal) - parseFloat(this.history.discount_amount);
      }

      switch (this.tax_type) {
        default:
          this.history.gst_amount = 0;
          this.history.gst_percentage = null;
          break;

        case "inclusive":
          this.history.gst_amount = parseFloat(subtotal) * (parseFloat(taxPercentage) / 100);
          this.history.gst_percentage = taxPercentage;
          break;
      }
      this.history.gst_amount = Number(this.history.gst_amount).toFixed(2);
      this.history.discount_amount = Number(this.history.discount_amount).toFixed(2);
      this.history.sub_total = Number(this.history.sub_total).toFixed(2);
      this.history.total_amount = parseFloat(subtotal) + parseFloat(this.history.gst_amount);
      this.history.total_amount = Number(this.history.total_amount).toFixed(2);
      this.calculateReturnCash();
    },

    validateForm() {
      this.$v.history.$touch();
      if (this.$v.history.$error) {
        setTimeout(() => {
          this.$v.history.$reset();
        }, 3000);
      } else {
        this.history.items = this.history_items;
        if (this.history_id) {
          this.updateHistory();
        } else {
          this.saveHistory();
        }
      }
    },

    saveHistory() {
      historyService.store(this.history).then((response) => {
        if(response.data.status == "OK") {
          this.redirectTo();
        }
      }).catch(() => {});
    },

    updateHistory() {
      historyService.update(this.history_id, this.history).then(() => {
        this.redirectTo();
      });
    },

    redirectTo() {
      this.$snotify.success("Information updated");
      this.$tabs.close().then(() => {
        this.$tabs.open({ name: "finance-history" });
      });
    },

    calculateReturnCash() {
      this.history.change_return = 0;
      this.disable_save = false;
      if (this.type == "expense") return;
      let total = this.history.total_amount ? this.history.total_amount : 0;
      let received = this.history.received_cash ? this.history.received_cash : 0;
      if (parseFloat(received) < parseFloat(total)) {
        this.disable_save = true;
        return true;
      }
      let difference = parseFloat(parseFloat(received) - parseFloat(total));
      difference = Number(difference).toFixed(2);
      this.history.change_return = Math.abs(difference);
      this.advanceCashReceived();
    },

    advanceCashReceived() {
      if (this.use_as_advance && this.history.change_return) {
        this.history.advance_received = this.history.change_return;
        this.history.change_return = 0;
        this.history.payment_method = null;
      }
      if (!this.use_as_advance && this.history.advance_received) {
        this.history.change_return = this.history.advance_received;
        this.history.advance_received = 0;
        this.history.payment_method = null;
      }
    },

    removeCashReturn() {
      this.history.advance_received = 0;
      this.history.change_return = 0;
      this.use_as_advance = false;
      this.calculateTotal();
    },

    changePaymentType() {
      this.paymentDetail = {};
      if (this.history.payment_method == "offline") {
        paymentGatewaySetting
            .findByType(this.history.payment_method)
            .then((response) => {
              this.paymentDetail = response.data.paymentGatewaySetting;
            })
            .catch((err) => {})
            .finally(() => {});
      }
    },
  },
};
</script>
